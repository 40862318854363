@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

#clients {

	.bsn-main {
		display: flex;
		justify-content: center;
		@include up-to-mobile {
			flex-wrap: wrap; } }

	.content {
		flex: 1 1 65%; }

	.sidebar {
		flex: 0 0 350px; }

	.image {
		overflow: hidden;
		background-color: $theme-shade-color-3;

		img {
			display: block;
			float: left;
			width: 47%;
			margin-top: 2%;
			margin-left: 2%;
			box-sizing: border-box; }

		figure {
			&:last-child {
				img {
					margin-bottom: 2%; } } } } }
