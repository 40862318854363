@import './../../settings/layout';
@import './../../settings/colors';
@import './../../settings/typography';
@import './../../settings/responsive';

@import './../../tools/functions';
@import './../../tools/mixins';
@import './../../tools/mixins-responsive';

.bsn-contact-information {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	@include up-to-mobile {
		flex-direction: column;
		align-items: center; } }

.bsn-contact-information__item {
	padding: 2px 10px; }

.bsn-contact-information__icon {
	padding-right: 5px;
	font-size: 14px; }

.bsn-contact-information__text {
	font-size: 14px;
	font-weight: 600; }

.bsn-contact-information__link {
	@include text-link($theme-color-1, normal); }
