@import './../settings/colors';
@import './../settings/typography';
@import './../settings/layout';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.mdl-loader {
	@include transition(all, 0.3s, ease-in-out);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	background-color: $theme-neutral-bg-color;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	cursor: not-allowed; }

.mdl-loader__spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 110;

	margin-top: -60px;
	margin-left: -60px; }
