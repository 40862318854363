@import './../../settings/layout';
@import './../../settings/colors';
@import './../../settings/typography';
@import './../../settings/responsive';

@import './../../tools/functions';
@import './../../tools/mixins';
@import './../../tools/mixins-responsive';

.bsn-header {
	position: relative;
	overflow: hidden;
	background: url('/images/background.jpg') center -220px no-repeat;

	@include up-to-mobile {
		background: url('/images/background.jpg') center -345px no-repeat; } }
