@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-contact-form {
	position: relative;
	display: flex;
	flex-wrap: wrap;

	@include up-to-tablet {
		padding: 20px 0; }

	@include up-to-tablet {
		width: 100%; }

	.cmp-contact-form__field {
		position: relative;
		flex: 1 0 50%;
		padding: 10px;
		box-sizing: border-box;
		@include up-to-mobile {
			flex: 1 0 100%; }

		&.cmp-contact-form__field--fullsize {
			flex: 1 0 100%; }

		&.cmp-contact-form__field--checkbox {
			display: flex;
			align-items: center;

			.cmp-text-field__input {
				order: 1; }
			.cmp-text-field__label {
				order: 2; } } }

	.cmp-contact-form__notification {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 10;
		background: rgba(255,255,255, 0.7);
		text-align: center;
		box-sizing: border-box; }

	&.cmp-contact-form--disabled {
		cursor: not-allowed;
		.cmp-contact-form__field {
			opacity: 0.5;
			user-select: none;
			* {
				z-index: -1; } } } }
