@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

#terms {

	h1 {
		font-size: 32px;
		margin-top: 20px;
		margin-bottom: 60px; }

	h3 {
		font-size: 24px;
		font-weight: 800;
		text-transform: uppercase;
		margin: 40px 0 20px 0; }

	p {
		margin-bottom: 20px;
		line-height: 1.6em; } }
