@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background-color: $mdc-theme-white;

	@include transition(opacity, 0.3s, cubic-bezier(0.65, 0.05, 0.36, 1));

	&--show {
		opacity: 0.85;
		z-index: 25; }

	&--hide {
		opacity: 0;
		z-index: -99999; } }
