@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-logo {
	position: absolute;
	top: -40px;
	left: 20px;
	z-index: 100;

	font-family: $mdc-font;
	font-size: 24px;
	font-weight: 600;
	color: set-text-color($mdc-color-primary);
	text-decoration: none;

	@include up-to-mobile {
		top: -30px;
		left: 50%;
		width: 100px;
		margin-left: -50px; } }

.cmp-logo__image {
	max-width: 100%;
	max-height: 100%; }
