$theme-primary-font: "Open sans", helvetica, arial, sans-serif;
$theme-secondary-font: "Myriad Pro Condensed", Calibri, arial, helvetica, sans-serif;
$theme-base-font-size: 18px;

$theme-font-size-default: 16px;
$theme-line-height-default: 1.5rem;


// --------------- \\
// material design \\
// --------------- \\

$mdc-font: Roboto, "Open sans", arial, sans-serif;

$mdc-font-size-default: 16px;
$mdc-font-size-mini: 12px;
$mdc-font-size-large: 18px;
$mdc-font-size-menu: 14px;
$mdc-line-height-default: 1.5rem;

$mdc-font-size-super-extra-small: 10px;
$mdc-font-size-extra-small: 12px;
$mdc-font-size-small: 14px;
$mdc-font-size-default: 16px;
$mdc-font-size-large: 18px;
$mdc-font-size-extra-large: 20px;
