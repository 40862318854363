@import './../../settings/layout';
@import './../../settings/colors';
@import './../../settings/typography';
@import './../../settings/responsive';

@import './../../tools/functions';
@import './../../tools/mixins';
@import './../../tools/mixins-responsive';

.bsn-footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 20px 0;
	background-color: $theme-color-4;
	@include layout-wrapper;
	@include up-to-mobile {
		flex-direction: column; } }
