@import './../../settings/layout';
@import './../../settings/colors';
@import './../../settings/typography';
@import './../../settings/responsive';

@import './../../tools/functions';
@import './../../tools/mixins';
@import './../../tools/mixins-responsive';

.bsn-main {
	margin-top: 70px;
	overflow: hidden;
	@include layout-wrapper;

	@include up-to-mobile {
		margin-top: 30px; } }
