@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-plain-html {

	ul, ol {
		list-style: disc;
		padding-left: 30px;
		padding-bottom: 10px; }

	p,ol,ul, {
		&+ul,&+ol,p,+.obj.button {
			margin-top: 30px; } }

	ol {
		list-style: decimal; }

	li {
		line-height: 2em;
		padding-bottom: 10px;
		padding-left: 20px; }

	img {
		max-width: 100%;
		max-height: 100%; } }
