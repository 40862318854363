@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-link {
	@include text-link($theme-color-1, normal); }
