@import './../settings/colors';
@import './../settings/typography';
@import './../settings/layout';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-error {}

.cmp-error__code {
	font-size: 120px;
	line-height: normal; }
