@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

$mdc-padding: 16px;

.cmp-button {
	display: inline-block;
	border: 2px solid transparent;
	padding: 12px 20px;
	background-color: #0f2749;
	color: #45afe4;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	font-style: normal;
	@include transition(all, 0.1s, ease-in-out); }

.cmp-button__text {
	display: block;
	line-height: 1em; }

.cmp-button--inverted {
	background-color: $theme-color-2;
	color: $theme-text-color-2;

	&:hover {
		border: 2px solid $theme-text-color-1;
		background-color: transparent;
		color: $theme-text-color-1; } }

.cmp-button--hollow {
	border-color: transparent;
	background-color: transparent;
	color: $theme-text-color-1;

	&:hover {
		border-color: $theme-color-1;
		// background-color: $theme-color-2
 } }		// color: $theme-text-color-2
