@import './../../settings/layout';
@import './../../settings/colors';
@import './../../settings/typography';
@import './../../settings/responsive';

@import './../../tools/functions';
@import './../../tools/mixins';
@import './../../tools/mixins-responsive';

.bsn-navigation-panel {
	width: 100%;
	margin-top: 80px;
	background-color: $theme-color-1;

	@include up-to-mobile {
		margin-top: 40px; } }

.bsn-navigation-panel__wrapper {
	position: relative;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	width: 100%;
	background-color: $theme-color-1;
	@include layout-wrapper;
	@include up-to-mobile {
		justify-content: space-between; } }

.bsn-navigation-panel__menus {
	display: flex;
	justify-content: flex-end; }
