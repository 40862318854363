@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

$mdc-menu-padding: 16px;

.cmp-menu {
	display: inline-flex;
	margin: 0 10px; }

.cmp-menu__list {
	display: flex;
	justify-content: flex-end; }

.cmp-menu__item {
	padding: 25px 0.5vw;
	font-family: $theme-primary-font;
	display: inline-block;
	text-transform: uppercase;
	color: $theme-text-color-2;
	font-weight: 600;
	font-size: 16px;

	@include up-to-mobile {
		padding: 10px 0.5vw; } }

.cmp-menu__title {
	position: relative;
	@include text-link($theme-color-4, normal, none);
	text-decoration: none;
	cursor: pointer; }

.cmp-menu-handler {
	display: flex;
	align-items: center;
	padding: 10px 0;
	color: $theme-text-color-2; }

.cmp-menu-handler__icon {
	padding-right: 5px; }

.cmp-menu--mobile {

	.cmp-menu__list {
		position: fixed;
		top: 0;
		left: -500px;
		z-index: 500;
		width: 100%;
		height: 100vh;
		background: $theme-color-1;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		@include transition(all, 0.3s, ease-in-out); }

	&.cmp-menu--show {
		.cmp-menu__list {
			left: 0; } }

	.cmp-menu__item--active {
		.cmp-menu__title {
			border-bottom: 3px solid $theme-color-4;
			color: $theme-color-4; } } }


.cmp-menu__item--active {
	.cmp-menu__title {
		border-bottom: 3px solid $theme-color-2;
		color: $theme-color-2; } }

.cmp-menu--show {
	.cmp-menu__list {
		opacity: 1;
		transform: translate(0, 0) scale(1); } }

.cmp-menu--hide {
	.cmp-menu__list {
		opacity: 0;
		transform: translate(0, 0) scale(0); } }
