$mdc-toolbar-padding: 16px;

.mdc.toolbar {
	position relative {}
	z-index: 10;
	width: 100%;
	background-color: $mdc-theme-neutral;
	color: set-text-color($mdc-theme-neutral);
	font-family: $mdc-font;

	overflow: hidden;
	@include transition(all, 0.2s, ease-in-out);

	@include desktop {
		height: 64px; }

	.left {
		float: left;
		width: 66.66666%; }

	.right {
		float: right;
		width: 33.33333%; }

	.title, .actions {
		line-height: $mdc-line-height-default; }

	.icon {
		padding: $mdc-toolbar-padding ($mdc-toolbar-padding / 2);
		line-height: $mdc-line-height-default;
		cursor: pointer;

		@include desktop {
			padding-top: 20px;
			padding-bottom: 20px; }

		&:last-of-type {
			padding-right: $mdc-toolbar-padding; }

		&.hide {
			display: none; } }

	.menu {
		float: left;

		.icon {
			padding: $mdc-toolbar-padding;

			@include desktop {
				padding-top: 20px; } } }

	.title {
		padding: $mdc-toolbar-padding;

		font-weight: 500;
		font-size: $mdc-font-size-large;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		@include desktop {
			padding: 20px; }

		img {
			display: block;
			max-height: 24px; } }

	.actions {
		text-align: right; }



	// behavior
	&.fixed {
		position: fixed;
		top: 0;
		left: 0; }

	&.prominent {
		height: 128px;

		.title {
			position: absolute;
			bottom: 0;
			left: 72px; } }



	// theming
	&.dark {
		background-color: $mdc-theme-dark;
		color: set-text-color($mdc-theme-dark);
		@include material-shadow(2); }

	&.light {
		background-color: $mdc-theme-light;
		color: set-text-color($mdc-theme-light);
		@include material-shadow(2); }

	&.color {
		background-color: $mdc-color-primary;
		color: set-text-color($mdc-color-primary);
		@include material-shadow(2); } }
