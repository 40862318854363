@import './../../settings/colors';
@import './../../settings/typography';
@import './../../settings/responsive';

@import './../../tools/functions';
@import './../../tools/mixins';
@import './../../tools/mixins-responsive';

@mixin snackbar-fab-overlap {
	@media (max-width: 444px) {
		@content; } }

$mdc-snackbar-width: 344px;
$mdc-snackbar-height: 48px;
$mdc-padding: 16px;

.mdc-snackbar {

	position: fixed;
	bottom: 1vh;
	left: 1vh;
	z-index: -1;

	display: block;

	width: auto;
	min-width: $mdc-snackbar-width;
	max-width: 100%;

	min-height: $mdc-snackbar-height;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 6px 0;
	overflow: hidden;

	background-color: #323232;
	color: #fff;

	opacity: 0;

	@include material-shadow(2);
	@include transition(all, 0.2s, ease-in-out);
	transition-property: transform, opacity;
	@include up-to-mobile {
		min-width: 0;
		max-width: calc(100% - 2vh);
		width: $mdc-snackbar-width; }

	&--show {
		transform: translate(0, 0);
		z-index: 9999;
		opacity: 1;

		// Add code to check if fab exists. Then uncomment the following lines
		// +snackbar-fab-overlap
 }		// 	transform: translate(0, -78px) // because of FAB usage (guideline says is should be place above fab)

	&--basic {
		.mdc-snackbar__action {
			color: lighten($mdc-color-primary, 20%);
			&:hover {
				background-color: rgba(lighten($mdc-color-primary, 20%), 0.1); }
			&:active {
				background-color: rgba(lighten($mdc-color-primary, 20%), 0.2); } } }

	&--warning {
		.mdc-snackbar__action {
			color: lighten($message-warning-color, 20%);
			&:hover {
				background-color: rgba(lighten($message-warning-color, 20%), 0.1); }
			&:active {
				background-color: rgba(lighten($message-warning-color, 20%), 0.2); } } }

	&--error {
		.mdc-snackbar__action {
			color: lighten($message-error-color, 20%);
			&:hover {
				background-color: rgba(lighten($message-error-color, 20%), 0.1); }
			&:active {
				background-color: rgba(lighten($message-error-color, 20%), 0.2); } } }

	&--success {
		.mdc-snackbar__action {
			color: lighten($message-success-color, 30%);
			&:hover {
				background-color: rgba(lighten($message-success-color, 30%), 0.1); }
			&:active {
				background-color: rgba(lighten($message-success-color, 30%), 0.2); } } }

	&--info {
		.mdc-snackbar__action {
			color: lighten($message-info-color, 30%);
			&:hover {
				background-color: rgba(lighten($message-info-color, 30%), 0.1); }
			&:active {
				background-color: rgba(lighten($message-info-color, 30%), 0.2); } } } }

.mdc-snackbar__message {
	display: block;
	float: left;

	padding: 8px $mdc-padding;

	line-height: 1.4em;
	font-size: $mdc-font-size-menu; }

.mdc-snackbar__action {
	display: block;
	float: right;

	margin: 0 $mdc-padding / 2 0 0;
	border: none;
	padding: 10px $mdc-padding / 2;

	line-height: 1em;
	font-size: $mdc-font-size-menu;
	color: $mdc-color-primary;
	background-color: transparent;
	text-transform: uppercase;

	cursor: pointer;
	outline: none;
	text-decoration: none;

	@include transition(all, 0.2s, ease-in-out); }

.mdc-snackbar__dismiss {
	display: block;
	float: left;
	border-radius: 50%;
	padding: 8px;

	cursor: pointer;

	&:hover {
		background-color: rgba(#fff, 0.15); }

	&:active {
		background-color: rgba(#fff, 0.3); } }

