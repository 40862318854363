@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';


%container-settings {
	max-width: 550px;
	margin: 0px auto;
	padding: 40px; }

#email-message {
	padding: 20px;
	background: #eee;

	.cmp-header {
		@extend %container-settings;
		background: $theme-color-1;
		text-align: center;
		border-bottom: 12px solid $theme-color-3;

		.cmp-header__title {
			display: none; } }

	.cmp-logo {
		position: static;
		display: inline-block;
		padding-bottom: 15px; }

	.cmp-content {
		@extend %container-settings;
		background: #fff;
		box-sizing: content-box;

		section {
			margin-bottom: 30px; }

		h1,h2,h3,h4,h5,h6 {
			margin-bottom: 10px;
			font-weight: 600;
			text-transform: none; }

		a {
			@include text-link($theme-color-1, normal); } }

	.cmp-footer {
		@extend %container-settings;
		padding: 20px 0 0 0;
		text-align: center;
		font-size: $mdc-font-size-small;
		color: rgba(#000, 0.7); }

	.cmp-copyright {
		justify-content: center; } }

.email-message__title {
	margin: 0;
	padding: 0;
	color: $mdc-theme-white;
	font-weight: 600;
	text-transform: none; }

.email-message__contact-details {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 0!important;
	padding: 0!important;
	list-style: none!important;

	li {
		padding: 0 5px !important; } }
