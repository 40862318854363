@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

#services {

	.bsn-main {
		display: flex;
		justify-content: center;
		@include up-to-mobile {
			flex-wrap: wrap; } }

	.content {
		flex: 1 1 65%;
		@include up-to-mobile {
			order: 2; } }

	.sidebar {
		flex: 1 0 360px;
		@include up-to-mobile {
			order: 1; }

		.service-selection {
			border: 1px solid $theme-shade-color-3; }

		ul {
			> li {
				padding: 20px;
				font-size: 18px;
				font-weight: 600;
				text-transform: uppercase;
				border-bottom: 1px solid $theme-shade-color-3;

				&:last-child {
					border-bottom: none; }

				.icon {
					display: inline-block;
					padding-right: 10px; } } } } }
