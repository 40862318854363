@import './../../settings/layout';
@import './../../settings/colors';
@import './../../settings/typography';
@import './../../settings/responsive';

@import './../../tools/functions';
@import './../../tools/mixins';
@import './../../tools/mixins-responsive';

$mdc-menu-padding: 16px;

.mdc-menu {

	display: block;

	border-radius: 4px;

	font-family: $mdc-font;
	background-color: $mdc-theme-white;

	@include material-shadow(2);
	@include transition(all, 0.15s, ease-in-out); }

.mdc-menu-item__title {
	float: left; }

.mdc-menu-item__icon {
	float: left;
	display: inline-block;
	width: 24px;
	padding: ($mdc-menu-padding / 2) ($mdc-menu-padding * 2) ($mdc-menu-padding / 2) $mdc-menu-padding;
	line-height: 2rem;
	font-size: 20px;
	color: rgba($mdc-theme-black, 0.54); }

.mdc-menu--show {
	opacity: 1;
	transform: translate(0, 0) scale(1); }

.mdc-menu--hide {
	opacity: 0;
	transform: translate(0, 0) scale(0); }

.mdc-menu--flat {
	background-color: transparent;
	@include material-shadow(0); }

.mdc-menu--fixed {
	position: fixed;
	top: $mdc-space-grain * 11;
	right: $mdc-space-grain * 4;
	z-index: 20;

	display: inline-block;

	min-width: 112px;
	max-width: 280px; }

.mdc-menu--text {
	a {
		padding: $mdc-menu-padding; } }



.mdc-menu-list {}

.mdc-menu-row {
	padding: 0 $mdc-space-grain * 4;
	&:hover {
		background-color: $mdc-theme-light; } }

.mdc-menu-item {
	display: block;

	overflow: hidden;

	line-height: 48px;
	text-decoration: none;
	font-size: $mdc-font-size-small;
	font-weight: 500;

	color: rgba($mdc-theme-black, 0.87);

	cursor: pointer;

	@include transition(all, 0.1s, ease-in-out);

	&:active {
		color: rgba($mdc-theme-black, 0.54); } }

.mdc-menu .mdc-list {
	background-color: transparent; }

.mdc-menu .mdc-list-item__title {
	font-size: $mdc-font-size-small;
	line-height: 24px; }

.mdc-menu .mdc-list-item__graphic {
	line-height: 24px; }

.mdc-menu--fixed .mdc-list-item__graphic {
	padding: 0; }
