@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-info-window__content {

	display: flex;
	min-width: 450px;

	.cmp-logo {
		flex: 1 1 35%;
		position: static;
		@include up-to-mobile {
			display: none; } }

	.bsn-contact-information {
		display: flex;
		flex: 1 1 65%;
		flex-direction: column;
		justify-content: space-evenly;
		@include up-to-mobile {
			align-items: flex-start; } } }

.cmp-info-window__footer {
	display: flex;
	justify-content: center; }

.cmp-info-window__link {
	@include text-link($theme-color-1, normal); }

.cmp-map {
	position: relative;
	width: 100%;
	height: 600px;

	@include up-to-tablet {
		width: 100%;
		height: 300px;
		margin-bottom: 40px; }

	@include up-to-mobile {
		width: 100%;
		height: 450px; } }
