@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

#contact {

	.bsn-main {
		display: flex;
		@include up-to-tablet {
			flex-direction: column; } }

	.contact-info {
		flex: 1 1 35%;

		.bsn-contact-information {

			flex-direction: column;
			align-items: stretch;

			@include up-to-tablet {
				flex-direction: row;
				justify-content: space-evenly; }

			@include up-to-mobile {
				flex-direction: column; }

			.bsn-contact-information__item {
				display: flex;
				flex: 0 0 calc(50% - 38px);
				margin: 20px 0;
				padding: 30px 15px;
				border: 2px solid $theme-color-2;
				@include up-to-tablet {
					margin: 10px 5px;
					padding: 25px 12px;
					align-items: center; }

				@include up-to-mobile {
					margin: 10px 0;
					padding: 20px 10px; } }

			.bsn-contact-information__icon {
				margin-right: 10px;
				font-size: 24px;
				color: $theme-color-2;
				@include up-to-tablet {
					font-size: 18px; } } } }

	.contact-form {
		flex: 1 1 65%; } }
