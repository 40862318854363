// --------------- \\
//  regular theme  \\
// --------------- \\

$theme-color-1: #0f2749;
$theme-color-2: #45afe4;
$theme-color-3: #e3000f;
$theme-color-4: #ffffff;

$theme-shade-color-1: #666666;
$theme-shade-color-2: #999999;
$theme-shade-color-3: #cccccc;
$theme-shade-color-4: #eeeeee;
$theme-shade-color-5: #f6f6f6;

$theme-neutral-bg-color: #ffffff;

$theme-text-color-1: #000000;
$theme-text-color-2: #ffffff;

$message-warning-color: #ee9922;
$message-error-color: #d21937;
$message-success-color: #349a23;
$message-info-color: #28acc1;

// --------------- \\
// material design \\
// --------------- \\

$mdc-color-primary: #28acc1;
$mdc-color-secondary: #6030c7;

$mdc-theme-black: #000000;
$mdc-theme-dark: #333333;
$mdc-theme-light: #fafafa;
$mdc-theme-neutral: #f2f2f2;
$mdc-theme-white: #ffffff;

$mdc-color-separator: #e2e2e2;

$mdc-color-error: #fa3843;

$mdc-color-text-primary: #232323;
$mdc-color-text-secondary: #777777;
