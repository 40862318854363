@import './../../settings/layout';
@import './../../settings/colors';
@import './../../settings/typography';
@import './../../settings/responsive';

@import './../../tools/functions';
@import './../../tools/mixins';
@import './../../tools/mixins-responsive';

.bsn-seo-banner {
	padding-top: 150px;
	padding-bottom: 60px;
	color: #eee;
	@include layout-wrapper;
	@include up-to-mobile {
		padding-top: 0; } }

.bsn-seo-banner__title {
	display: inline-block;
	margin-top: 60px;
	margin-bottom: 80px;
	padding: 20px 30px;
	font-size: 28px;
	font-weight: 600;
	color: $theme-color-4;
	background-color: $theme-color-2;
	text-transform: uppercase;

	@include up-to-mobile {
		margin-top: 60px;
		margin-bottom: 0;
		padding: 10px 20px;
		text-align: center;
		line-height: 1.2em;
		font-size: 6vw; } }

.bsn-seo-banner__description {
	margin: 40px 0;
	max-width: 650px;
	color: $theme-text-color-1; }

.bsn-seo-banner {

	.cmp-button {
		margin-top: 40px;
		margin-right: 20px;

		@include up-to-mobile {
			display: block;
			text-align: center; } } }
