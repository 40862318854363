@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

#e-404 {

	padding: 60px;
	text-align: center;

	.cmp-logo {
		position: static;

		@include up-to-tablet {
			margin: 0; } }

	.cmp-copyright {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 10;

		width: 100%;
		padding: 20px 0;
		justify-content: center; } }
