@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-copyright {
	display: flex;
	padding: 0 10px;
	align-items: center;
	font-size: $mdc-font-size-small;
	color: $theme-shade-color-2;
	@include up-to-mobile {
		justify-content: center; } }
