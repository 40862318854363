@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-version {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 500;
	width: 100%;

	background: rgba($mdc-theme-white, 0.8);
	text-align: center;
	font-family: monospace; }
