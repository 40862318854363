@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

#gallery {

	.bsn-main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include up-to-mobile {
			flex-wrap: wrap; } }


	.gallery {

		@include up-to-tablet {
			width: 700px; }

		.item {
			position: relative;
			float: left;
			width: 32.333333333%;
			margin: 0.5%; } } }
