@import './../../settings/layout';
@import './../../settings/colors';
@import './../../settings/typography';
@import './../../settings/responsive';

@import './../../tools/functions';
@import './../../tools/mixins';
@import './../../tools/mixins-responsive';

.bsn-gallery {
	position: relative;

	.cmp-gallery__filters {
		padding: 40px 0 20px 0; } }
