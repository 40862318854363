.mdc.bottom-sheet {

	position: fixed;
	bottom: -2000px;
	left: 0;
	z-index: 1;

	width: 100%;
	height: 100%;

	@include transition(all, 0.4s, cubic-bezier(0.65, 0.05, 0.36, 1));
	opacity: 0;

	background-color: #f2f2f2;

	&.show {
		z-index: 80;
		opacity: 1;
		transform: translate(0, -2000px);

		.content {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			z-index: 2;

			overflow-y: auto; } } }
