@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-modal {
	position: relative;
	z-index: -1;
	opacity: 0;
	@include transition(all, 0.15s, ease-in-out); }

.cmp-modal--visible {
	z-index: 150;
	opacity: 1; }

.cmp-modal__overlay {
	position: fixed;
	z-index: 200;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background: rgba(0, 0, 0, 0.7);
	overflow: hidden; }

.cmp-modal__content {
	position: absolute;
	z-index: 250;
	top: 5vh;
	left: 20vw;
	width: 60vw;
	height: 90vh;

	padding: 20px 20px 40px 20px;
	background: #fff;

	box-sizing: border-box;
	border-radius: 8px;
	box-shadow: 0 0 40px -10px #000;

	@include up-to-tablet {
		left: 5vw;
		width: 90vw; } }

.cmp-modal__main {
	height: calc(90vh - 60px);
	overflow-y: auto; }

.cmp-modal__dismiss {
	position: absolute;
	bottom: 0;
	width: calc(100% - 40px);
	height: 40px;
	box-sizing: border-box;
	padding: 10px 0;
	text-align: center;
	font-size: $mdc-font-size-extra-small;
	background: #fff; }
