@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

#about {

	.bsn-main {
		display: flex;
		justify-content: center;
		@include up-to-mobile {
			flex-wrap: wrap; } }

	.content {
		flex: 1 1 100%;
		max-width: 1000px; } }
