@import './../settings/colors';
@import './../settings/typography';
@import './../settings/layout';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-gallery {
	display: flex;
	flex-direction: column;
	align-content: center; }

.cmp-gallery__images {
	display: flex;
	flex-wrap: wrap;
	justify-content: center; }

.cmp-gallery__filters {
	display: flex;
	justify-content: center;

	@include up-to-mobile {
		flex-direction: column;
		align-items: center; } }

.cmp-gallery__filter {
	display: inline-block;
	padding: 0 20px;
	@include text-link($theme-color-1, normal, none);
	font-size: $mdc-font-size-small;
	font-weight: 800;
	text-transform: uppercase;
	cursor: pointer;

	&.cmp-gallery__filter--active {
		color: $theme-color-2; } }

.cmp-gallery-item {
	position: relative;
	width: 312px;
	height: 416px;
	margin: 5px;

	&:hover {
		.cmp-gallery-item__overlay {
			opacity: 0.9;
			transform: scale(1); } } }

.cmp-gallery-item__image {
	max-width: 100%;
	max-height: 100%; }

.cmp-gallery-item__overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 500;
	width: 100%;
	height: 100%;
	background-color: $theme-color-1;
	opacity: 0;
	cursor: pointer;

	@include transition(all, 0.1s, ease-in-out);
	transform: scale(0); }

.cmp-gallery-item__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -15px;
	margin-left: -15px;
	font-size: 30px;
	color: $theme-text-color-2; }

.cmp-gallery--empty-state {
	padding: 40px;
	font-style: italic; }

.cmp-gallery__images--empty-state {
	padding: 40px;
	font-style: italic; }
