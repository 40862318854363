@import './../settings/layout';

@mixin layout-wrapper() {
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;

	@include desktop {
		padding-left: $mdc-space-grain * 6;
		padding-right: $mdc-space-grain * 6; } }

@mixin text-link($color: $theme-color-3, $style: italic, $border: 1px solid $color) {

	color: $color;
	border-color: $color;
	font-style: $style;
	border-bottom: $border;
	cursor: pointer;

	&:hover {
		color: lighten($color, 20%);
		border-color: lighten($color, 20%); } }

@mixin transition($transition-property, $transition-time, $method) {
	transition: $transition-property $transition-time $method; }

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table; }

    &:after {
        clear: both; } }

@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: linear-gradient($direction, $color-stops); }

@mixin ellipsis() {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap; }

@mixin material-shadow($level: 1, $animate: "none") {

	// levels {0-5}
	@if $level == 0 {
		box-shadow: none; }
	@if $level == 1 {
		box-shadow: 0 2px 4px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
	@else if $level == 2 {
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }
	@else if $level == 3 {
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); }
	@else if $level == 4 {
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); }
	@else if $level == 5 {
		box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); }

	// states {none|hoverable}
	@if $animate == "hoverable" {
		@include transition(all, 0.3s, cubic-bezier(0.25,0.8,0.25,1));

		&:hover {
			box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); }

		&:active {
			box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); } } }
