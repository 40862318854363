@mixin up-to-mobile {
	@media (max-width: #{$tablet-width}) {
		@content; } }

@mixin up-to-tablet {
	@media (max-width: #{$desktop-width}) {
		@content; } }

@mixin mobile {
	@media (max-width: #{$mobile-width}) {
		@content; } }

@mixin tablet {
	@media (min-width: #{$tablet-width + 1}) and (max-width: #{$desktop-width}) {
		@content; } }

@mixin desktop {
	@media (min-width: #{$desktop-width + 1}) {
		@content; } }
