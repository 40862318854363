@import './../../settings/colors';
@import './../../settings/typography';
@import './../../settings/responsive';

@import './../../tools/functions';
@import './../../tools/mixins';
@import './../../tools/mixins-responsive';

$app-bar-height-mobile: 56px;
$app-bar-height-desktop: 64px;
$mdc-padding: 16px;

.mdc-side-nav {
	position: fixed;
	top: 0;
	left: -200px;
	z-index: 200;

	width: auto;
	height: 100%;

	background-color: #fff;
	overflow: hidden;
	overflow-y: auto;
	@include transition(transform, 0.4s, cubic-bezier(0.65, 0.05, 0.36, 1));
	@include material-shadow(5);

	@include up-to-mobile {
		width: calc(100% - 56px); }

	&--permanent { // fixed, always visible, shifts the main content to the right
 		@include desktop;
			position: fixed;
			top: $app-bar-height-desktop;
			left: 0;
			width: $app-bar-height-desktop * 5;
			background-color: transparent;
			@include material-shadow(0);
			border-right: 1px solid $mdc-color-separator;
			z-index: 1; }

	&--show {
		opacity: 1;
		visibility: visible;
		transform: translate(200px, 0); }

	&--hide {
		transform: translate(-500px, 0); }

	>.cmp-heading {
		position: relative;
		height: 144px;
		padding: $mdc-padding;
		background-color: $mdc-color-primary;
		color: set-text-color($mdc-color-primary);
		@include desktop {
			display: none; }

		>.cmp-heading__title {
			position: absolute;
			bottom: $mdc-padding; } } }
