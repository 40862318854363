@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

$cmp-spacing: 10px;

.cmp-content {
	padding: $cmp-spacing;
	box-sizing: border-box;

	figure {
		margin: $cmp-spacing 0;
		display: block;
		text-align: center; }

	figcaption {
		font-size: $mdc-font-size-small;
		font-style: italic;
		color: $theme-shade-color-2; }

	img {
		max-width: 100%;
		max-height: 100%; } }
