@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

#unsubscribe {

	.bsn-main {
		display: flex;
		justify-content: center;
		@include up-to-tablet {
			flex-direction: column; } }

	.cmp-content {
		max-width: 600px; }

	.cmp-logo {
		position: static;
		display: flex;
		margin: 40px 0 0 0;
		justify-content: center;
		@include up-to-mobile {
			width: auto; } }

	.cmp-header__title {
		margin: 40px 0 120px; }

	.cmp-copyright {
		justify-content: center; }

	.unsubscribe__back-to-home-link {
		padding: $mdc-space-grain * 10 0 $mdc-space-grain 0;
		text-align: center; } }
