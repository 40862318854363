@import './../settings/colors';
@import './../settings/typography';
@import './../settings/layout';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-text-field {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch; }


.cmp-text-field__input {

	box-sizing: border-box;
	border: 1px solid $theme-shade-color-3;
	padding: 10px 30px 10px 15px;
	font-family: $theme-primary-font;
	outline: none;

	&:hover {
		background-color: $theme-color-4;
		color: $theme-text-color-1;
		border-color: $theme-text-color-1; } }

.cmp-text-field__input--textarea {
	height: 120px;
	width: 100%;

	resize: none;

	line-height: 20px;
	font-family: $theme-primary-font;
	font-size: $mdc-font-size-small;

	background-color: transparent; }

.cmp-text-field__input--dropdown {
	appearance: none; }


.cmp-text-field__label {
	display: inline-block;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 12px;
	color: $theme-shade-color-1; }


.cmp-text-field__icon {
	position: absolute;
	top: 38px;
	right: 10px;
	color: $theme-shade-color-2; }


.cmp-text-field__helper-text {
	display: block;

	height: 16px;
	padding: 0;

	line-height: 24px;
	font-size: $mdc-font-size-extra-small;
	font-style: italic;
	color: rgba($mdc-theme-black, 0.6); }


.cmp-text-field__wrapper {
	display: flex;
	align-items: center; }

.cmp-text-field--checkbox {
	align-items: flex-start;

	.cmp-text-field__input {
		order: 1; }

	.cmp-text-field__label {
		order: 2; } }


.cmp-text-field--active {

	.cmp-text-field__label {
		color: $theme-color-2; }

	.cmp-text-field__input {
		border-color: $theme-color-2; } }

.cmp-text-field--invalid {
	.cmp-text-field__input {
		border-color: $mdc-color-error; }

	.cmp-text-field__label {
		color: $mdc-color-error; }

	.cmp-text-field__helper-text {
		color: $mdc-color-error; } }

.cmp-text-field--dense {
	.cmp-text-field__input {
		height: 40px;
		padding: $mdc-space-grain * 3; }

	.cmp-text-field__label {
		display: none; } }
