@import './../settings/layout';
@import './../settings/colors';
@import './../settings/typography';
@import './../settings/responsive';

@import './../tools/functions';
@import './../tools/mixins';
@import './../tools/mixins-responsive';

.cmp-header {}

.cmp-header__title {
	margin-top: 100px;
	margin-bottom: 120px;
	font-size: 48px;
	line-height: 1.2em;
	text-align: center;
	color: $theme-color-1;
	text-transform: uppercase;

	@include up-to-mobile {
		margin-top: 50px;
		margin-bottom: 60px; } }
