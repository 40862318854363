html, body {
	line-height: $theme-line-height-default;
	font-size: $theme-font-size-default; }

body {
	font-family: $theme-primary-font;
	font-size: 18px;
	background-color: $mdc-theme-white; }

.layout-wrapper {
	@include layout-wrapper; }

h1, h2, h3, h4, h5, h6 {
	display: block;
	color: $theme-color-1;
	font-family: $theme-primary-font;
	font-weight: 800; }

h2 {
	font-size: 22px;
	text-transform: uppercase;
	margin-bottom: 30px; }

body {
	background-color: $theme-neutral-bg-color; }

section {
		margin-bottom: 60px; }

p {
	font-size: 16px;
	line-height: 1.8em;
 }	// color: $theme-text-color-1

li {
	color: $theme-text-color-1; }

a {
	text-decoration: none; }

strong {
	font-weight: 600; }

em {
	font-style: italic; }

.fa-question,
.fa-question-circle,
.fa-question-circle-o {
	cursor: help; }

.hidden {
	display: none; }

// --------------- \\
// material design \\
// --------------- \\

html, body {
	line-height: $mdc-line-height-default;
	font-size: $mdc-font-size-default;
	font-family: $mdc-font; }


